import request from "@/utils/request";

// 获取五十音分类
export function FiftyType() {
    return request({
        url: '/admin/korean_v3/fortyType',
        method: 'get'
    })
}

// 获取五十音列表
export function FiftyNotesList(params) {
    return request({
        url: '/admin/korean_v3/fortyNotesList',
        method: 'get',
        params
    })
}


// 获取五十音上传token
export function getFiftyCosToken(params) {
    return request({
        url: '/admin/korean_v3/getFortyCosToken',
        method: 'get',
        params
    })
}

// 编辑五十音
export function FiftyNotesEdit(params) {
    return request({
        url: '/admin/korean_v3/fortyNotesEdit',
        method: 'put',
        params
    })
}